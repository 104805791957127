import React from 'react'
import styled from 'styled-components'
import { ChevronRight } from 'react-feather'

const WorkInfoWrapper = styled.div`
  border: 1px solid var(--lipstick);
  max-width: 1080px;
  margin: 40px auto;
  display: grid;
  grid-template-columns: 1fr;
  border-radius: 4px;

  @media screen and (min-width: 1080px) {
    grid-template-columns: 1fr 1fr;
  }
`

const WorkInfoInner = styled.div`
    padding: 10px 20px;
`

const Header = styled.h2`
    margin-top: 20px;
    font-size: 1.414rem;
    margin-bottom: 4px;
`

const Text = styled.p`
    margin-top: 10px;
`
const WorkInfo = ({data}) => {
  return (
    <WorkInfoWrapper>
      {data.map((info) => {
        return (
          <WorkInfoInner key={info.title}>
            <Header>{info.title}</Header>
            <Text><ChevronRight size="17px" color="var(--lipstick)"/> {info.text}</Text>
          </WorkInfoInner>
        )
      })}
    </WorkInfoWrapper>
  )
}

export default WorkInfo
