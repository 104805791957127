import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
    position: relative;
    overflow: hidden;
    width: 100%;

    &::after {
        display: block;
        content: "";
        padding-top: 56.25%;
    }

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
`

const VideoWrapper = ({ videoId, title }) => {
  return (
    <Wrapper>
      <iframe
        title={title}
        width="560"
        height="315"
        src={`https://www.youtube.com/embed/${videoId}`}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </Wrapper>
  )
}

export default VideoWrapper
